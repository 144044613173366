import styles from "./HomePage.module.scss";
import { LinkButton } from "../../components/LinkButton";
import { useAuth } from "../../service/auth/AuthProvider";
import { usePharmacy } from "../../hooks/usePharmacy";
import { useHistory } from "react-router";
import { Footer } from "../../components/Footer";

export const HomePage: React.VFC = () => {
  const { signOut } = useAuth();
  const history = useHistory();

  const onSignOut = () => {
    signOut();
  };

  const { pharmacy } = usePharmacy();

  if (pharmacy === null) {
    history.replace("/pharmacies");
    return null;
  }

  return (
    <main>
      <section className={styles.sec_home01}>
        <p className={styles.sec_home01_logout}>
          <button
            className={`${styles.glb_btn02} ${styles.type_xs}`}
            onClick={onSignOut}
          >
            ログアウト
          </button>
        </p>
        <p className={styles.sec_home01_ttl}>{pharmacy ? pharmacy.name : ""}</p>
        <p className={styles.sec_home01_text}>
          電子お薬手帳用QRコードからおくすり情報を読み取ることができます。
        </p>
        <p className={styles.sec_home01_btn}>
          <LinkButton url="/qrcode">QRコードを読む</LinkButton>
        </p>
        <Footer />
      </section>
    </main>
  );
};
