import axiosClient from "@aspida/axios";
import { datadogLogs } from "@datadog/browser-logs";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import type { Pharmacy } from "../hooks/usePharmacy";
import { getToken } from "../service/auth";
import {
  KEYS,
  getItem,
  getItemAndParse,
  setItem,
} from "../service/localStorage";

const getOrCreateUUID = () => {
  if (typeof window !== "undefined") {
    let uuid = getItemAndParse(KEYS.CLIENT_UUID);
    if (!uuid) {
      uuid = uuidv4();
      setItem(KEYS.CLIENT_UUID, uuid);
    }
    return uuid;
  }
  return null;
};

const clientUUID = getOrCreateUUID();

axios.interceptors.request.use(
  async (config) => {
    try {
      const token = await getToken();
      const savedPharmacy = getItemAndParse(
        KEYS.SELECTED_PHARMACY
      ) as Pharmacy | null;

      if (savedPharmacy) {
        config.headers["X-Pharmacy-Id"] = savedPharmacy.id;
      }
      if (token) {
        config.headers.Authorization = token;
      }
    } catch (error) {
      console.error("Error in request interceptor:", error);
    }

    const useDefaultDomain = getItem(KEYS.USE_DEFAULT_DOMAIN);
    if (useDefaultDomain === "true") {
      config.baseURL = process.env.REACT_APP_API_URL_DEFAULT_DOMAIN;
    } else {
      config.baseURL = process.env.REACT_APP_API_URL;
    }

    return config;
  },
  (error) => {
    if (!error.response) {
      datadogLogs.logger.error(
        `リクエスト前にエラーが発生しました： ${error.message} clientUUID: ${clientUUID}`,
        {
          name: error.name,
          code: error.code,
          config: error.config,
          request: error.request,
          clientUUID: clientUUID,
        }
      );
    }
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      datadogLogs.logger.error(
        `リクエストエラーが発生しました： ${error.message} clientUUID: ${clientUUID}`,
        {
          name: error.name,
          code: error.code,
          config: error.config,
          request: error.request,
          clientUUID: clientUUID,
        }
      );
    }
    return Promise.reject(error);
  }
);

export const apiClient = axiosClient(axios, {
  baseURL: process.env.REACT_APP_API_URL,
});
