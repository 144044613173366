import styles from "./QRcodeRegistrationCompletedPage.module.scss";
import { Link } from "react-router-dom";
import { Header } from "../../components/Header";
import { usePatient } from "../../hooks/usePatient";
import { Footer } from "../../components/Footer";

export const QRcodeRegistrationCompletedPage: React.VFC = () => {
  const { patient } = usePatient();

  return (
    <div>
      <Header title="内容確認" />
      <main>
        <section className={styles.sec_dialog01}>
          <p className={styles.sec_dialog01_text}>
            {patient}さんの処方を
            <br />
            登録しました。
          </p>
          <p className={styles.sec_dialog01_btn}>
            <Link to="/home" className={styles.glb_btn03}>
              ホーム画面
            </Link>
            <Link to="/qrcode" className={styles.glb_btn01}>
              QRコードを読む
            </Link>
          </p>
        </section>
      </main>
      <Footer />
    </div>
  );
};
