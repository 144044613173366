import { useState, useEffect } from "react";
import { Header } from "../../components/Header";
import { useHistory } from "react-router-dom";
import { usePatients } from "../../hooks/usePatients";
import { usePatient } from "../../hooks/usePatient";
import { useRawPrescription } from "../../hooks/useRawPrescription";
import api from "../../api/pharmacy_app/prescriptions/$api";
import { apiClient } from "../../lib/apiClient";
import styles from "./PatientsPage.module.scss";
import useLoadingOverlay from "../../hooks/useLoadingOverlay";
import { usePharmacy } from "../../hooks/usePharmacy";
import { datadogLogs } from "@datadog/browser-logs";
import { Footer } from "../../components/Footer";

export const PatientsPage: React.VFC = () => {
  const history = useHistory();
  const { patients } = usePatients();
  const [_enabled, setEnable] = useState(false);
  const [_index, setIndex] = useState<number | null>(null);
  const { setPatient } = usePatient();
  const { rawPrescription } = useRawPrescription();
  const { showLoadingOverlay, closeLoadingOverlay } = useLoadingOverlay();

  const { pharmacy } = usePharmacy();

  if (pharmacy === null) {
    history.replace("/pharmacies");
    return null;
  }

  const onCheck = (index: number) => {
    setIndex(index);
    setEnable(true);
  };

  const onSubmit = () => {
    // FIXME: 本来であればpタグではなくbuttonタグで非活性化する処理を入れるべき
    if (_index === null) {
      return;
    }

    if (rawPrescription === null) {
      history.push("/qrcode-registration-error");
      return;
    }

    showLoadingOverlay();
    api(apiClient)
      .post({
        body: {
          patient_id: patients[_index].id,
          raw_prescription: rawPrescription,
        },
      })
      .then(() => {
        history.push("/qrcode-registration-completed");
      })
      .catch((e) => {
        datadogLogs.logger.error(JSON.stringify(e));
        if (e.response.status >= 500 && e.response.status < 600) {
          history.push("/error-500");
        } else {
          history.push("/qrcode-registration-error");
        }
      })
      .finally(() => {
        setPatient(patients[_index].name);
        closeLoadingOverlay();
      });
  };

  useEffect(() => {
    if (patients.length === 1) {
      setEnable(true);
      setIndex(0);
    }
  });

  return (
    <div>
      <Header hasBackButton title="内容確認" />
      <main className={styles.main}>
        <section className={styles.sec_check01}>
          <ul className={styles.glb_list01}>
            {patients.length > 1 && (
              <li className={styles.type_error}>
                名前・生年月日が一致する患者が複数存在します。今回の処方を登録する患者を選択してください。
                <br />
                一度登録した情報は変更できませんのでご注意ください。
              </li>
            )}
            {patients.length === 0 && (
              <li className={styles.type_error}>
                名前・生年月日が一致する患者が登録されていません。
              </li>
            )}
            {patients.length > 0 && (
              <li>
                <div className={styles.glb_list01_main}>{patients[0].name}</div>
                <div className={styles.glb_list01_sub}>
                  <ul className={styles.glb_list01_list}>
                    <li>{patients[0].name_kana}</li>
                    <li>{patients[0].birthday}</li>
                  </ul>
                </div>
              </li>
            )}
          </ul>
          {patients.length > 0 && (
            <h2 className={styles.glb_ttl01}>名前・生年月日が一致する患者</h2>
          )}
          <ul
            className={
              styles.glb_list01 +
              " " +
              (patients.length > 1 ? styles.type_check : "")
            }
          >
            {patients.map((patient, index) => (
              <li
                key={index}
                onClick={() => onCheck(index)}
                className={index == _index ? styles.is_check : ""}
              >
                <div className={styles.glb_list01_main}>{patient.name}</div>
                <div className={styles.glb_list01_sub}>
                  <ul className={styles.glb_list01_list}>
                    <li>{patient.name_kana}</li>
                    <li>{patient.birthday}</li>
                    <li>{patient.created_date} 登録</li>
                  </ul>
                </div>
                <div className={styles.glb_list01_info}>
                  <ul className={styles.glb_list02}>
                    <li className={styles.glb_list02_ttl}>前回の処方</li>
                    {patient.latest_prescription_items.map((item, index) => (
                      <li key={index}>
                        <p className={styles.glb_list02_main}>
                          {item.medicine_name}
                        </p>
                        <p className={styles.glb_list02_sub}>
                          {item.amount_unit +
                            " " +
                            item.usage +
                            " " +
                            item.dispensed_quantity_unit}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            ))}
          </ul>
          <div className={styles.glb_box02}>
            <p
              className={styles.glb_btn02}
              onClick={() => history.push("./home")}
            >
              キャンセル
            </p>
            <p
              className={
                styles.glb_btn03 + " " + (_enabled ? "" : styles.is_disable)
              }
              onClick={onSubmit}
            >
              登録する
            </p>
          </div>
        </section>
        <Footer className={styles.footer} isDark />
      </main>
    </div>
  );
};
