// localStorage は key: value の形式で保存するのだが
// value の型が変わった時にユーザのブラウザの localStorage で古い型の value が保存されているとエラーが出るかもしれないので、
// それを避けるために LocalStorage の保存に使うキーは後ろにバージョンつけて、型が変わる時に変えるという運用にする
export enum KEYS {
  SELECTED_PHARMACY = "SELECTED_PHARMACY_1.0",
  CLIENT_UUID = "CLIENT_UUID_1.0",
  USE_DEFAULT_DOMAIN = "USE_DEFAULT_DOMAIN",
}

export function removeItem(key: KEYS) {
  localStorage.removeItem(key);
}

export function setItem(key: KEYS, value: unknown) {
  try {
    if (typeof value === "object") {
      localStorage.setItem(key, JSON.stringify(value));
      return;
    }
    localStorage.setItem(key, `${value}`);
  } catch (error) {
    // tslint:disable-next-line no-console
    console.error(error);
  }
}

export function getItem(key: KEYS) {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    // tslint:disable-next-line no-console
    console.error(error);
    return null;
  }
}

export function getItemAndParse(key: KEYS) {
  try {
    const item = localStorage.getItem(key);
    if (item) {
      return JSON.parse(item);
    }
    return null;
  } catch (e) {
    return null;
  }
}
