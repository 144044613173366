import Maintenance from "../components/Maintenance";
import { useFlags } from "launchdarkly-react-client-sdk";

type Props = {
  children: React.ReactElement;
};

export const MaintenanceProvider: React.FC<Props> = ({ children }) => {
  const flags = useFlags();

  if (flags.pharmacyAppMaintenanceState) {
    return <Maintenance />;
  }

  return children;
};
