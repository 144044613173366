import { FC, ReactNode } from "react";
import styles from "./Footer.module.scss";

type ListItemProps = {
  href: string;
  children: ReactNode;
};

const ListItem: FC<ListItemProps> = ({ href, children }) => (
  <li className={styles.listItem}>
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  </li>
);

type Props = {
  isDark?: boolean;
  className?: string;
};

export const Footer: FC<Props> = ({ isDark, className = "" }) => {
  return (
    <footer
      className={`${styles.module} ${isDark ? styles.isDark : ""} ${className}`}
    >
      <ul className={styles.list}>
        <ListItem href="https://support.musubi.app/hc/ja/articles/20955256462745">
          利用規約
        </ListItem>
        <ListItem href="https://www.kakehashi.life/external-policy">
          顧客情報の外部送信
        </ListItem>
        <ListItem href="https://www.kakehashi.life/privacy-policy">
          個人情報保護方針
        </ListItem>
      </ul>
    </footer>
  );
};
