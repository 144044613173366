import { Header } from "../../components/Header";
import { ErrorPage } from "../../pages/ErrorPages/components/ErrorPage";
import React from "react";

export const Maintenance: React.FC = () => {
  return (
    <div>
      <Header title="メンテナンス" />
      <ErrorPage
        title="メンテナンス中"
        description={
          <>
            ただ今メンテナンス中です。
            <br />
            しばらくしてから再度お試しください。
          </>
        }
      />
    </div>
  );
};

export default Maintenance;
